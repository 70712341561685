/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";
import { useIntl } from "react-intl";
import { getAverageUse } from "../_requests";
type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
};

const TotalChangesWidget: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const chartRef2 = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const intl = useIntl();
  const [briefChange, setBriefChange] = useState<any>();
  useEffect(() =>{
    getAverageUse("day").then((res: any) => {
      setBriefChange(res);
      console.log(res);
    });
  }, []);
  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartColor, chartHeight, intl)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  const refreshChart2 = () => {
    if (!chartRef2.current) {
      return;
    }

    const chart2 = new ApexCharts(
      chartRef2.current,
      chart2Options(chartColor, chartHeight, intl, 20)
    );
    if (chart2) {
      chart2.render();
    }

    return chart2;
  };

  useEffect(() => {
    const chart = refreshChart();
    const chart2 = refreshChart2();

    return () => {
      if (chart) {
        chart.destroy();
      }
      if (chart2) {
        chart2.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, chartRef2, mode]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5 justify-content-center">
        <h2 className="card-title align-items-center justify-content-center d-flex flex-row">
          <span className="card-label text-center fw-bold mb-1">
            {intl.formatMessage({ id: "TOTAL.CHANGES.SPENT" })}
          </span>
        </h2>

        {/* begin::Toolbar */}
        <div className="mx-auto">
          <div className="card-toolbar" data-kt-buttons="true">
            <a
              className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1"
              id="kt_charts_widget_8_year_btn"
            >
              {intl.formatMessage({ id: "MONTH" })}
            </a>

            <a
              className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1"
              id="kt_charts_widget_8_month_btn"
            >
              {intl.formatMessage({ id: "WEEK" })}
            </a>

            <a
              className="button-tab btn-active btn-active-primary active px-4"
              id="kt_charts_widget_8_week_btn"
            >
              {intl.formatMessage({ id: "DAY" })}
            </a>
          </div>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div
        className="card-body d-flex flex-column h-375px"
        style={{ margin: 0, padding: 0 }}
      >
        <div ref={chartRef} className="mixed-widget-4-chart"></div>
        <div
          ref={chartRef2}
          className="mixed-widget-4-chart"
          style={{ marginTop: "-70px", paddingBottom: 0, marginBottom: 0 }}
        ></div>
      </div>
      {/* end::Body */}
    </div>
  );
};

const chartOptions = (
  chartColor: string,
  chartHeight: string,
  Intl: any
): ApexOptions => {
  const baseColor = getCSSVariableValue("--bs-primary");
  const lightColor = getCSSVariableValue("--bs-primary-light");
  const primaryColor = "#2F2E7C"; //getCSSVariableValue("--bs-primary");
  const labelColor = getCSSVariableValue("--bs-gray-700");

  return {
    series: [60],
    chart: {
      fontFamily: "inherit",
      height: chartHeight,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: "70%",
        },
        dataLabels: {
          name: {
            show: true,
            fontWeight: "700",
            color: "#171717",
            fontSize: "14px",
          },
          value: {
            color: "#2F2E7C",
            fontSize: "22px",
            fontFamily: "Poppins-bold",
            fontWeight: "bold",
            offsetY: -50,
            show: true,
            formatter: function (val) {
              return val.toString();
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: "100%",
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: "round",
    },
    labels: [Intl.formatMessage({ id: "CHANGES.TODAY" })],
  };
};

const chart2Options = (
  chartColor: string,
  chartHeight: string,
  Intl: any,
  avgBriefChanges: number
): ApexOptions => {
  const baseColor = "#D2478A";
  const primaryColor = getCSSVariableValue("--bs-primary");
  const lightColor = getCSSVariableValue("--bs-" + chartColor + "-light");
  const labelColor = getCSSVariableValue("--bs-gray-700");

  return {
    series: [avgBriefChanges],
    chart: {
      fontFamily: "inherit",
      height: chartHeight,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: "70%",
        },

        dataLabels: {
          name: {
            show: true,
            fontWeight: "700",
            color: "#171717",
            fontSize: "14px",
          },

          value: {
            color: primaryColor,
            fontSize: "22px",
            fontFamily: "Poppins-bold",
            fontWeight: "bold",

            offsetY: -50,
            show: true,
            formatter: function (val) {
              return val.toString();
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: "100%",
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: "round",
    },
    labels: [Intl.formatMessage({ id: "AVERAGE.TODAY" })],
  };
};

export { TotalChangesWidget };
