import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../_metronic/helpers";
import { useLang } from "../../../_metronic/i18n/Metronici18n";

const API_URL = process.env.REACT_APP_API_URL;
const GET_BRIEF_CHANGES = `${API_URL}/api/v1/brief-changes`;
const USER_URL = `${API_URL}/api/v1/user`;

axios.defaults.headers.common["Accept-Language"] =
  localStorage.getItem("lang")!;

const getAverageUse = (timeUnit: string): any => {
  return axios
    .get(`${GET_BRIEF_CHANGES}`, { params: { timeUnit } })
    .then((d: AxiosResponse<any>) => d.data);
};

export { getAverageUse };
