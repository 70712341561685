import axios, { AxiosResponse } from "axios";
import { UserModel } from "./_models";
import { Response } from "../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/v1/user/me`;
export const LOGIN_URL = `${API_URL}/api/v1/validate-credentials`;
export const CREATE_ADMIN_URL = `${API_URL}/api/v1/user/admins`;
export const REQUEST_PASSWORD_URL = `${API_URL}/api/v1/auth/password/forgot`;
export const APPLE_LOGIN_URL = `${API_URL}/api/v1/auth/sign-in/apple`;
export const GOOGLE_LOGIN_URL = `${API_URL}/api/v1/auth/admin/sign-in/google`;
export const VERIFY_CODE_URL = `${API_URL}/api/v1/auth/password/verify-code`;
//export const UPDATE_PASSWORD_URL = `${API_URL}/api/v1/auth/password/reset`;
export const UPDATE_PASSWORD_URL = `${API_URL}/api/v1/reset-shopify-password`;
const USER_URL = `${API_URL}/api/v1/user`;

axios.defaults.headers.common["Accept-Language"] = localStorage.getItem("lang")!;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  });
}

export function googleLogin(access_token: string) {
  return axios.post(GOOGLE_LOGIN_URL, {
    access_token,
  });
}

export function appleLogin(idToken: string) {
  return axios.post(APPLE_LOGIN_URL, {
    idToken,
  });
}

// Server should return AuthModel
export function createAdmin(
  email: string,
  first_name: string,
  last_name: string,
  password: string
) {
  return axios.post(CREATE_ADMIN_URL, {
    email,
    first_name,
    last_name,
    password,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPasswordVerifyCode(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUser() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
}

export const updateUser = (
  userId: number,
  first_name: string,
  last_name: string
): Promise<UserModel | undefined> => {
  return axios
    .patch(USER_URL + "/" + userId + "/info", { first_name, last_name })
    .then((response: AxiosResponse<Response<UserModel>>) => response.data)
    .then((response: Response<UserModel>) => response.data);
};

export function requestVerifyCode(email: string) {
  return axios.post(`${USER_URL}/request-email-code`, {
    email,
  });
}
export function verifyCode(email: string, code: string) {
  return axios.post(`${VERIFY_CODE_URL}`, {
    email,
    code,
  });
}

export const updateEmail = (
  userId: number,
  email: string,
  code: string
): Promise<UserModel | undefined> => {
  return axios
    .patch(USER_URL + "/" + userId + "/email", { email, code })
    .then((response: AxiosResponse<Response<UserModel>>) => response.data)
    .then((response: Response<UserModel>) => response.data);
};

export const updatePassword = (
  email: string,
  new_password: string,
  confirm_password: string,
): Promise<UserModel | undefined> => {
  return axios
    .post(`${UPDATE_PASSWORD_URL}`, { email, new_password, confirm_password })
    .then((response: AxiosResponse<Response<UserModel>>) => response.data)
    .then((response: Response<UserModel>) => response.data);
};
